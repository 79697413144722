import { BASE_URL } from '../../../../environments/environment';



const LOGIN_URL = '/api/auth/login';
const FORGET_PASSWORD_URL = '/api/auth/forgot-password';
const SIGNUP_URL = '/api/auth/signup';
const RESET_PASSWORD_URL = '/api/auth/reset-password';
const CHANGE_PASSWORD_URL = '/api/auth/change-password';
const LOGOUT_URL = '/api/auth/logout';

const DASHBOARD_URL = '/admin/dashboard';
const STATUS_URL = '/api/auth/status';
const PROFILE_URL = '/api/auth/user';
const leaveURL = '/api/leave-message';





export function loginURL() {
  return BASE_URL + LOGIN_URL;
}

export function signupURL() {
  return BASE_URL + SIGNUP_URL;
}

export function leaveMessageURL() {
  return BASE_URL + leaveURL;
}

export function changePasswordURL() {
  return BASE_URL + CHANGE_PASSWORD_URL;
}

export function resetPasswordURL() {
  return BASE_URL + RESET_PASSWORD_URL;
}

export function forgotPasswordURL() {
  return BASE_URL + FORGET_PASSWORD_URL;
}

export function logoutURL() {
  return BASE_URL + LOGOUT_URL;
}

export function dashboardURL() {
  return BASE_URL + DASHBOARD_URL;
}

export function updateStatusURL() {
  return BASE_URL + STATUS_URL;
}

export function updateProfileURL() {
  return BASE_URL + PROFILE_URL;
}

export const ADD_CUSTOMER = BASE_URL + '/admin/customer';



