import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import {Observable} from 'rxjs';
@Injectable()
export class ChatService {

    constructor(private socket: Socket) {
        this.clock = Observable.interval(1000).map(tick => new Date()).share();
        // Setup the new Howl.
    }
    cameraModal: any;
    thanksModal: any;
    waitingModal: any;
    switchModal: any;
    rejectModal: any;
    private clock: Observable<Date>;
    providerName: any;

    connect() {
        this.socket.connect();
    }

    disconnect() {
        return this.socket
            .fromEvent('disconnect');
    }

    getClock(): Observable<Date> {
        return this.clock;
    }

    setProviderName(providerName) {
        this.providerName = providerName;
    }

    getProviderName() {
        return this.providerName;
    }

    getModal(type) {
        let modal;
        switch (type) {
            case 'thanks':
            modal = this.thanksModal;
                break;
            case 'waiting':
                modal = this.waitingModal;
                break;
            case 'switch':
                modal = this.switchModal;
                break;
            case 'reject':
                modal = this.rejectModal;
                break;
            default:
            modal = this.cameraModal;
                break;
        }
        return modal;
    }

    setModal(modal, type) {
        switch (type) {
            case 'thanks':
                this.thanksModal = modal;
                break;
            case 'camera':
                this.cameraModal = modal;
                break;
            case 'waiting':
                this.waitingModal = modal;
                break;
            case 'switch':
                this.switchModal = modal;
                break;
            case 'reject':
                this.rejectModal = modal;
                break;
            default:
                break;
        }

    }

    getSocketId() {
        return this.socket.ioSocket.id;
    }

    getSocket() {
        return this.socket;
    }

    userJoin() {
        this.socket.emit('user_join');
    }
    providerJoin() {
        this.socket.emit('provider_join');
    }
    sendTextChatRequest() {
        this.socket.emit('send_text_chat_request');
    }

    sendVideoChatRequest() {
        this.socket.emit('send_video_chat_request');
    }

    getTextChatRequest() {
        return this.socket
            .fromEvent('text_chat_request');
    }

    switchVideoChatRequest() {
        return this.socket.fromEvent('switch_video_chat_request');
    }

    getVideoChatRequest() {
        return this.socket
            .fromEvent('video_chat_request')
            .map( data => data );
    }

    isTyping() {
        return this.socket
            .fromEvent('typing')
            .map( data => data );
    }

    getRejectedVideoRequest() {
        return this.socket
            .fromEvent('rejected_video_request')
            .map( data => data );
    }

    acceptTextChatRequest(sid, provider_name) {
        this.socket.emit('accepted_text_chat_request', {'from': sid, 'provider_name': provider_name});
    }

    rejectedVideoRequest(sid) {
        this.socket.emit('rejected_video_request', {'from': sid});
    }

    acceptVideoChatRequest(sid, provider_name) {
        this.socket.emit('accepted_video_chat_request', {'from': sid, 'provider_name': provider_name});
    }

    providerAcceptedVideoChat() {
        return this.socket
            .fromEvent('provider_accepted_video_chat')
            .map( data => data );
    }

    providerAcceptedTextChat() {
        return this.socket
            .fromEvent('provider_accepted_text_chat')
            .map( data => data );
    }

    sendMessage(msg, from) {
        this.socket.emit('message', {to: from, message: msg});
    }
    getMessage() {
        return this.socket
            .fromEvent('message')
            .map( data => data );
    }

    checkChatStatus() {
        return this.socket
            .fromEvent('end_chat')
            .map( data => data );
    }

    checkRoomStatus() {
        return this.socket
            .fromEvent('room_full')
            .map( data => data );
    }

    close() {
        this.socket.disconnect(this.socket);
    }

    endChat(from) {
        this.socket.emit('end_chat', {to: from});
    }

    leaveRoom() {
        this.socket.emit('leave_room');
    }

    userLeft() {
        return this.socket
        .fromEvent('user_left')
        .map( data => data );
    }

    getcancelledRequest() {
        return this.socket
        .fromEvent('cancelled_request')
        .map( data => data );
    }

    setBusyCallback(from) {
        this.socket.emit('provider_busy', {to: from});
    }
    cancelledRequest() {
        this.socket.emit('cancelled_request');
    }
}
