import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from '../shared/services/chatService.service';
import { TokenManager } from '../shared/services/api-services/token-manager.service';
import { PlatformLocation } from '@angular/common';
import { WebRtcService } from '../shared/services/WebRtcService.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Observable } from 'rxjs/Observable';
import { Socket } from 'ngx-socket-io';

import * as $ from 'jquery';

declare global {
  interface Element {
    mozRequestFullScreen?(): void;
  }
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild( PerfectScrollbarComponent) private perfectscroll: PerfectScrollbarComponent;
  chattype: string;
  showTextChat = false;
  showVideoChat = false;
  windowpad: number;
  messageText: string;
  doctor_video: any;
  patient_video: any;
  constraints = { audio: true, video: true };
  private modalRef: NgbModalRef;
  cameraError: any;
  isMuted = false;
  messages = [];
  startVideoChat: any;
  currentDate = Date.now();
  allSubscription = new Subscription();
  showfullScreen = false;
  isTyping = false;
  providerName: any;
  config: any;
  streaming = false;
  canLeave = false;
  // tslint:disable-next-line:max-line-length
  constructor(private modalService: NgbModal, private router: Router, private route: ActivatedRoute, private webrtc: WebRtcService, private ChatService: ChatService, private tokenManager: TokenManager, location: PlatformLocation) {
    this.route.queryParams.subscribe(params => {
      this.chattype = params['type'];
    });

    this.allSubscription.add(webrtc.receiveAnswer().subscribe((result: any) => {
      this.webrtc.processReceiveAnswer(result.socketId, result.sdp);
    }));

    this.allSubscription.add(webrtc.onIceCandidate().subscribe((result: any) => {
      this.webrtc.iceCandidate(result);
    }));

    this.allSubscription.add(ChatService.getMessage().subscribe((result: any) => {
      this.isTyping = false;
      this.messages.push({message: result.message, dtime: Date.now(), msg_type: 'receive'});
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    }));

    this.allSubscription.add(ChatService.checkChatStatus().subscribe((result: any) => {
      this.close();
      let el = document.getElementsByClassName('modal-backdrop');
      if (el[0]) {
        el[0].remove();
      }
      this.open(this.ChatService.getModal('thanks') , 'thanks-modal');
      setTimeout(() => {
        this.close();
      }, 10000);
      this.tokenManager.deleteSocketData();
      this.ChatService.leaveRoom();
      this.webrtc.endChat();
      this.canLeave = true;
      this.router.navigate(['/']);
    }));

    this.allSubscription.add(ChatService.userLeft().subscribe((result: any) => {
      this.close();
      let el = document.getElementsByClassName('modal-backdrop');
      if (el[0]) {
        el[0].remove();
      }

      this.webrtc.endChat();
      this.ChatService.leaveRoom();
      this.canLeave = true;
      this.router.navigate(['/']);
    }));

    this.allSubscription.add(webrtc.startVideoChat().subscribe((result: any) => {
      this.close();
      this.showVideoChat = true;
      this.showTextChat = true;
      this.callforVideo();
    }));

    this.allSubscription.add(webrtc.cameraNotFound().subscribe((result: any) => {
    this.close();
    this.open(this.ChatService.getModal('camera') , 'thanks-modal');
    if (this.chattype === 'videochat') {
      this.tokenManager.deleteSocketData();
      this.ChatService.leaveRoom();
      this.canLeave = true;
      this.router.navigate(['/']);
    } else {
      this.showVideoChat = false;
      this.showTextChat = true;
    }
  }));

  this.allSubscription.add(this.ChatService.getRejectedVideoRequest().subscribe((result: any) => {
    this.close();
    this.open(this.ChatService.getModal('reject') , 'waiting-modal');
    setTimeout(() => {
      this.close();
    }, 5000);
  }));

  this.allSubscription.add(this.ChatService.getClock().subscribe((result: any) => {
    this.currentDate = result;
  }));

  // this.allSubscription.add(this.ChatService.disconnect().subscribe((result: any) => {
  //   console.log('socket disconnectned now.');
  //   if (!this.ChatService.getSocketId()) {
  //     this.tokenManager.deleteSocketData();
  //     this.ChatService.connect();
  //   }
  // }));

  this.allSubscription.add(ChatService.isTyping().subscribe((result: any) => {
    this.isTyping = true;
    setTimeout(() => {
      this.isTyping = false;
    }, 1500);
  }));
}

  ngOnInit() {
    this.providerName = this.ChatService.getProviderName();
      this.onFocus();
      if (!this.ChatService.getSocketId()) {
        this.tokenManager.deleteSocketData();
        this.canLeave = true;
        this.router.navigate(['/']);
      }

      if (this.chattype === 'videochat') {
        this.showVideoChat = true;
        this.showTextChat = true;
      } else {
        this.showVideoChat = false;
        this.showTextChat = true;
      }
  }
  ngOnDestroy() {
    this.allSubscription.unsubscribe();
  }

  open(content, classes) {
    this.modalRef = this.modalService.open(content , { 'windowClass': classes , 'backdrop': 'static'});
   }
   close() {
     if (this.modalRef) {
      this.modalRef.close();
     }
  }
  showTypingStatus() {
    this.webrtc.sendSocketMessage('typing', {'from': this.tokenManager.getSocketData().from});
  }

  switchToVideo(cameraModal) {
    this.open(this.ChatService.getModal('waiting') , 'waiting-modal');
    this.webrtc.sendSocketMessage('switch_video_chat_request', {'to': this.tokenManager.getSocketData().from});
  }

  interchangeStream() {
    this.webrtc.setVideoTrackToggle();
  }

  sendMessage(msg) {
    const msgs = msg.trim();
    if ( msgs ) {
      this.ChatService.sendMessage(msg, this.tokenManager.getSocketData().from);
      this.messages.push({message: msg, dtime: Date.now(), msg_type: 'send'});
    }
    this.messageText = null;
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
  }

  endChat() {
    this.ChatService.endChat(this.tokenManager.getSocketData().from);
    this.webrtc.endChat();
    this.close();
    this.open(this.ChatService.getModal('thanks') , 'thanks-modal');
    setTimeout(() => {
      this.close();
    }, 10000);

    this.ChatService.leaveRoom();
    this.webrtc.endChat();
    this.canLeave = true;
    this.router.navigate(['/']);
  }

 toggleMute() {
    this.isMuted = !this.isMuted;
    this.webrtc.setAudioTrackToggle();
  }

   togglefullScreen() {
      let elem = document.getElementById('doctor_video');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        }
  }


  callforVideo() {
   // sample
   let startbutton;
   this.doctor_video = document.getElementById('doctor_video');
   this.patient_video = document.getElementById('patient_video');
   startbutton = document.getElementById('capture_image');
   navigator.mediaDevices.getUserMedia(this.constraints).then(stream => {
     this.patient_video.srcObject = stream;
     this.patient_video.muted = true;
     this.patient_video.play();
     this.webrtc.init(this.tokenManager.getSocketData().from);
     this.webrtc.setMyMediaStream(stream, this.doctor_video, this.patient_video);
     this.webrtc.sendOffer(this.tokenManager.getSocketData().from);
   },
   error => {
     this.webrtc.sendSocketMessage('camera_not_found', {'to': this.tokenManager.getSocketData().from});
    if (this.chattype === 'videochat') {
      this.tokenManager.deleteSocketData();
      this.ChatService.leaveRoom();
      this.canLeave = true;
      this.router.navigate(['/']);
    } else {
      this.showVideoChat = false;
      this.showTextChat = true;
    }
   });
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    document.getElementById('typing_area').focus();
    this.sectionHeight();
    this.typingSecPosition();
    this.scrollToBottom();
    window.onresize = () => {
      this.sectionHeight();
      this.typingSecPosition();
    };

    $('#doctor_video').on('play', function (e) {
      setTimeout(function(){
        var video_height = $('#doctor_video').height()
        var ratio = (($(window).height() - $('#TopHeader').height() - 68) * 0.8) / video_height;
        var margin = $('#doctor_video').height() * 0.5 * (ratio - 1);

        $('#doctor_video').css('transform', 'scale(' + ratio + ', ' + ratio + ')');
        $('#doctor_video').parent().css('height', (video_height * ratio) + 'px');
        $('#doctor_video').css('margin-top', margin + 'px');
      }, 1500);
    });
  }
  ngAfterViewChecked() {
     this.typingSecPosition();
     this.sectionHeight();
     if (!this.ChatService.getSocketId()) {
      this.tokenManager.deleteSocketData();
      this.ChatService.leaveRoom();
      this.canLeave = true;
      this.router.navigate(['/']);
     }
  }
  sectionHeight() {
    if (window.matchMedia('(max-width: 1199px)').matches) {
      this.windowpad = 51;
    } else {
      this.windowpad = 61;
    }
    const header_height = document.getElementsByClassName('topheader')[0].clientHeight;
    const pageHeader_height = document.getElementsByClassName('page_header')[0].clientHeight;
    const typingSec_height = document.getElementsByClassName('typing_sec')[0].clientHeight;
    const window_height = window.innerHeight;
    const chatSec_height = (window_height - typingSec_height - header_height - pageHeader_height - this.windowpad);
    let chatId =  document.getElementById('chating_sec');
    if (chatId) {
      chatId .style.height = (chatSec_height) + 'px';
    }
  }

   // @HostListener allows us to also guard against browser refresh, close, etc.
   @HostListener('window:beforeunload')
   canDeactivate(): Observable<boolean> | boolean {
     // insert logic to check if there are pending changes here;
     // returning true will navigate without confirmation
     // returning false will show a confirm dialog before navigating away
     return this.canLeave;
   }

  typingSecPosition() {
    const window_height = window.innerHeight;
    const chat_page_height = document.getElementsByClassName('chat_page')[0].clientHeight;
      if (window_height > chat_page_height) {
        document.getElementById('chat_page').classList.add('bottom');
      } else {
        document.getElementById('chat_page').classList.remove('bottom');
      }
  }
  scrollToBottom(): void {
    this.perfectscroll.directiveRef.scrollToBottom();
    }
  onFocus() {
    const x = document.getElementById('typing_area');
    const y = document.getElementById('sendBtn');
    y.addEventListener('click', myClickFuncyion, true);
    x.addEventListener('focus', myFocusFunction, true);
    x.addEventListener('blur', myBlurFunction, true);
    function myClickFuncyion() {
      document.getElementById('typing_form_group').classList.remove('focused');
    }
    function myFocusFunction() {
      document.getElementById('typing_form_group').classList.add('focused');
    }
    function myBlurFunction(e) {
      if (e.target.textLength === 0) {
        document.getElementById('typing_form_group').classList.remove('focused');
      } else {
       document.getElementById('typing_form_group').classList.add('focused');
      }
    }
  }
}
