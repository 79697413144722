import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ChatService } from '../shared/services/chatService.service';
import { TokenManager } from '../shared/services/api-services/token-manager.service';
import { NgForm } from '@angular/forms';
import { ToasterService } from '../shared/services/toaster.service';
import { AuthService } from '../auth/services/auth-service.service';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  closeResult: string;
  isAcceptedRequest = false;
  currentRequestType = '';
  timer: any;
  tinymce: any;
  private modalRef: NgbModalRef;
  leaveMessageModel = {'email': '', 'phone': '', 'name': '', 'question': ''};
  loading = false;
  // tslint:disable-next-line:max-line-length
  constructor(private modalService: NgbModal, private auth: AuthService, private toasterService: ToasterService, private router: Router, private ChatService: ChatService, private tokenManager: TokenManager ) {
   }

  ngOnInit() {
    if (!this.ChatService.getSocketId()) {
      this.ChatService.userJoin();
    } else {
      this.ChatService.close();
      this.ChatService.connect();
    }

    this.ChatService.providerAcceptedTextChat().subscribe((result: any) => {
      this.ChatService.setProviderName(result.data.provider_name);
      this.isAcceptedRequest = true;
      this.tokenManager.setSocketData(result);
      this.redirectUser();
    });
    this.ChatService.providerAcceptedVideoChat().subscribe((result: any) => {
      this.ChatService.setProviderName(result.data.provider_name);
      this.isAcceptedRequest = true;
      this. tokenManager.setSocketData(result);
      this.redirectUser();
    });
  }
  ngAfterViewInit() {
      this.portraitDevice();
      window.onresize = () => {
      this.portraitDevice();
    };
  }

  ngOnDestroy() {
    // this.ChatService.close();
  }

  leaveMessage (frm: NgForm) {
    this.loading = true;
    this.auth.leaveMessage(frm.value).subscribe({
      next: (response) => {
        if (response.success) {
          this.toasterService.Success(response.message);
          this.loading = false;
          frm.resetForm();
          document.getElementById('leaveModal').classList.remove('show');
          let el = document.getElementsByClassName('modal-backdrop');
          if (el[0]) {
            el[0].remove();
          }
        }
      },
      error: (err) => {
        this.loading = false;
        this.toasterService.Error(err.message);
      }
    });
  }

  handleEventEditor(Obj, el) {
  }

  registerModals(cameraModal, thanksModal, rejectModal, waitingModal) {
    this.ChatService.setModal(cameraModal, 'camera');
    this.ChatService.setModal(thanksModal, 'thanks');
    this.ChatService.setModal(rejectModal, 'reject');
    this.ChatService.setModal(waitingModal, 'waiting');
  }

  sendRequest(type, model, busymodal) {
    // connection code goes here according to chat type
    if ( type === 'videochat') {
      this.ChatService.sendVideoChatRequest();
      this.currentRequestType = type;
      this.open(model, 'waiting-modal modal_sm');
    } else {
      this.ChatService.sendTextChatRequest();
      this.currentRequestType = type;
      this.open(model, 'waiting-modal modal_sm');
    }
    this.timer = setTimeout(() => {
      this.close();
      this.open(busymodal, 'busy-modal modal-md');
    }, 31000);
  }

  redirectUser() {
      this.close();
      clearTimeout(this.timer);
      this.router.navigate(['/chat'], { queryParams: { 'type': this.currentRequestType } });
  }

  open(content, classes) {
    this.modalRef = this.modalService.open(content , { 'windowClass': classes , 'backdrop': 'static'});
   }
   close() {
    this.modalRef.close();
  }

  showAboutModal(aboutModal) {
    this.open(aboutModal, 'about-modal');
  }
  cancelRequest() {
    clearTimeout(this.timer);
    this.close();
    this.ChatService.cancelledRequest();
  }

  portraitDevice(){
    const div = document.getElementsByClassName('home-page')[0];
    if(window.innerHeight > window.innerWidth){
      div.classList.add('portrait-mode');
    }else{
      div.classList.remove('portrait-mode');
    }
  }
}
