import {Injectable} from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService} from '../../auth/services/auth-service.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url = state.url;
        if (this.auth.isLoggedIn() === true) {
            // TODO check if token hasn't expired
            return true;
        }
        // this.auth.redirectUrl = url;
        this.router.navigate(['/provider/auth/login']);
        return false;
    }
}
