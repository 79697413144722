import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave.component.html',
  styleUrls: ['./confirm-leave.component.scss']
})
export class ConfirmLeaveComponent implements OnInit {

  subject: Subject<boolean>;

  constructor(public ngbModalRef: NgbModalRef) { }

  ngOnInit() {}

}
