import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';


interface Extras {
    contentType: {
        isFormDataContent?: boolean;
        isJsonContent?: boolean;
    };
}

@Injectable()
export class ApiHandler {

    constructor(private http: HttpClient) {

    }

    apiGet(url, extras?: Extras) {
        const options = this.renderHeaders(extras);
        return this.http.get(url, options).timeout(30000);
    }

    apiPost(url, reqBody, extras?: Extras) {
        const options = this.renderHeaders(extras);
        return this.http.post(url, reqBody, options).timeout(30000);
    }

    apiUpdate(url, reqBody, extras?: Extras) {
        const options = this.renderHeaders(extras);
        return this.http.put(url, reqBody, options).timeout(30000);
    }

    apiDelete(url, extras?) {
        const options = this.renderHeaders(extras);
        return this.http.delete(url);
    }

    private renderHeaders(extras: Extras) {
        // if extras is present then apply check
        if (extras) {
            if (extras.contentType.isFormDataContent) {
                return {};
            }
        } else {
            // else assume it to be json data
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
        }
    }
}

