import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {TokenManager} from './api-services/token-manager.service';
import {ChatService} from './chatService.service';

declare global {
  interface RTCPeerConnection {
    ontrack?(event: any): void;
  }
}

@Injectable()
export class WebRtcService {
  socket: any;
  pc: any;
  myMediaStream: any;
  remoteMediaStream: any;
  peerId: string;
  patient_video: any;
  doctor_video: any;
  isVideoToggle = false;
  // construct the simplewebrtc object as service creation
  constructor(
      private chatService: ChatService, private tokenManager: TokenManager) {
      if (!this.socket) {
        this.socket = this.chatService.getSocket();
      }
      this.peerId = this.chatService.getSocketId();
      this.init(tokenManager.getSocketData().from);
  }

  init(socketId) {
    this.pc = this.createPeerConnection(socketId);
  }

  sendSocketMessage(command, message) {
    if (this.socket) {
      this.socket.emit(command, message);
    } else {
      console.log('No socket connection found.');
    }
  }
  setMyMediaStream(stream, doctor_video, patient_video) {
    stream.getTracks().forEach((t) => this.pc.addTrack(t, stream));
    this.myMediaStream = stream;
    this.doctor_video = doctor_video;
    this.patient_video = patient_video;
  }

  getMyMediaStream() {
    return this.myMediaStream;
  }

  createPeerConnection(socketId) {
    let config = {'optional': [{'googIPv6': true}]};
    // let ICE_config = {'iceServers': [{'urls': 'stun:stun.l.google.com:19302'}]};
    let ICE_config = {'iceServers': [
      {'urls': ['stun:prortc.com:3478'], 'username': '', 'credential': ''}, {
        'urls': ['turn:45.55.131.122:3478'],
        'username': 'navalp',
        'credential': 'codiant2016'
      }
    ]};
    let id = socketId;
    let pc = new RTCPeerConnection(ICE_config);
    pc.onicecandidate = ((event: any) => {
      if (event.candidate) {
        this.sendSocketMessage('ice_candidate', {
          label: event.candidate.sdpMLineIndex,
          id: event.candidate.sdpMid,
          candidate: event.candidate.candidate,
          'socketId': id,
        });
      }
    });
    pc.oniceconnectionstatechange = ((event: any) => {
      if (pc.iceConnectionState === 'failed') {
        this.sendSocketMessage('ice_failed', {socketId: id});
      }
    });
    pc.ontrack  = ((event: any) => {
      console.log('on remote stream');
      let doctor_video: any = document.getElementById('doctor_video');
      doctor_video.srcObject = event.streams[0];
      doctor_video.play();
      this.remoteMediaStream = event.streams[0];
    });
    return pc;
  }
  getOfferConstraints() {
    let mediaConstraints = {
      mandatory: {'OfferToReceiveAudio': true, 'OfferToReceiveVideo': true}
    };
    return mediaConstraints;
  }

  sendOffer(socketId) {
    let pc;
    pc = this.pc;
    if (pc) {
      pc.createOffer(
          ((session_description: any) => {
             // session_description.sdp =
               // this.setMediaBitrate(session_description.sdp, 50);
            pc.setLocalDescription(session_description);
            console.error('offer sent');
            this.sendSocketMessage(
                'send_offer',
                {'socketId': socketId, 'sdp': session_description});
          }),
          function(error) {
            console.error('sending offer error ' + error);
          },
          this.getOfferConstraints()).catch(e => console.log('Here: ' + e.name));
    } else {
      console.error('peer not found');
    }
  }

  setMediaBitrate(sdp, bandwidth) {
    let modifier = 'AS';
    let adapter: any;
    if (adapter) {
      if (adapter.browserDetails.browser === 'firefox') {
        bandwidth = (bandwidth >>> 0) * 1000;
        modifier = 'TIAS';
      }
    }
    let media = 'video';
    let lines = sdp.split('\n');
    let line = -1;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].indexOf('m=' + media) === 0) {
        line = i;
        break;
      }
    }
    if (line === -1) {
      console.log('Could not find the m line for', media);
      return sdp;
    }
    console.log('Found the m line for', media, 'at line', line);
    // Pass the m line
    line++;
    // Skip i and c lines
    while (lines[line].indexOf('i=') === 0 || lines[line].indexOf('c=') === 0) {
      line++;
    }
    // If we're on a b line, replace it
    if (lines[line].indexOf('b') === 0) {
      console.log('Replaced b line at line', line);
      lines[line] = 'b=' + modifier + ':' + bandwidth;
      return lines.join('\n');
    }
    // Add a new b line
    console.log('Adding new b line before line', line);
    let newLines = lines.slice(0, line);
    newLines.push('b=' + modifier + ':' + bandwidth);
    if (adapter) {
      if (adapter.browserDetails.browser === 'firefox') {
        newLines.push('r=jesup');
      }
    }
    newLines = newLines.concat(lines.slice(line, lines.length));
    return newLines.join('\n');
  }

  receiveOffer() {
    return this.socket.fromEvent('receive_offer');
  }

  startVideoChat() {
    return this.socket.fromEvent('start_video_chat');
  }

  cameraNotFound() {
    return this.socket.fromEvent('camera_not_found');
  }

  receiveAnswer() {
    return this.socket.fromEvent('receive_answer');
  }

  onIceCandidate() {
    return this.socket.fromEvent('ice_candidate');
  }

  iceCandidate(data) {
    let candidate = new RTCIceCandidate(
        {sdpMLineIndex: data.label, candidate: data.candidate});
    this.pc.addIceCandidate(candidate);
  }

  sendAnswer(socketId, sdp) {
    let pc;
    pc = this.pc;
    let offer;
    pc.setRemoteDescription(new RTCSessionDescription(sdp));
    pc.createAnswer(
        ((session_description: any) => {
          // session_description.sdp =
            //  this.setMediaBitrate(session_description.sdp, 50);
            console.error('answer sent');
          pc.setLocalDescription(session_description);
          this.sendSocketMessage(
              'send_answer',
              {'socketId': socketId, 'sdp': session_description});
          offer = pc.remoteDescription;
        }),
        function(error) {
          console.log('answer error', error);
        },
        this.getOfferConstraints());
  }

  processReceiveAnswer(socketId, sdp) {
    let pc;
    pc = this.pc;
    console.log('answer set');
    pc.setRemoteDescription(new RTCSessionDescription(sdp));
  }

  setAudioTrackToggle() {
    console.log('audio');
    this.myMediaStream.getAudioTracks()[0].enabled = !this.myMediaStream.getAudioTracks()[0].enabled;
  }
  setVideoTrackToggle() {
    console.log('video');
    if (this.isVideoToggle) {
      this.doctor_video.srcObject = this.remoteMediaStream;
      this.doctor_video.muted = false;
      this.patient_video.srcObject = this.myMediaStream;
      this.patient_video.muted = true;
      this.isVideoToggle = false;
    } else {
      this.doctor_video.srcObject = this.myMediaStream;
      this.patient_video.muted = false;
      this.patient_video.srcObject = this.remoteMediaStream;
      this.doctor_video.muted = true;
      this.isVideoToggle = true;
    }
     this.doctor_video.play();
     this.patient_video.play();
  }

  endChat() {
    if (this.myMediaStream) {
      this.myMediaStream.getTracks().forEach((t) => t.stop());
    }
    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }
  }

  // add more as you need
}
