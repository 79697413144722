import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ChatComponent } from './chat/chat.component';
import { SharedModule } from './shared/module/shared.module';
import { NotFoundComponent } from './shared/component/not-found/not-found.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { AuthService } from './auth/services/auth-service.service';
import { LeaveGuard } from './auth/services/leave-guard.service';

// export function getAuthModule() { return AuthModule; }
// export function getResetPasswordModule() { return ResetPasswordModule; }
// export function getProviderModule() { return ProviderModule; }

const routes: Routes = [];

const AppRoutes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'about-us', component: AboutComponent},
  {path: 'chat', component: ChatComponent, canDeactivate: [LeaveGuard]},
  {path: 'provider/auth', loadChildren: './auth/auth.module#AuthModule'},
  { path: 'reset-password/:token', loadChildren: './auth/reset-password/reset.module#ResetPasswordModule' },
  {path: 'provider', loadChildren: './provider/provider.module#ProviderModule', canActivate: [AuthGuard]},
  {path: 'not-found', component: NotFoundComponent },
  {path: '**', redirectTo: 'not-found' }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(AppRoutes, {
      enableTracing: false,
      useHash: true
    })
  ],
  exports: [RouterModule],
  declarations: [NotFoundComponent],
  providers: [AuthGuard, AuthService],
})
export class AppRoutingModule { }
