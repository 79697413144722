import { Injectable } from '@angular/core';

@Injectable()
export class TokenManager {

    constructor() {}

    fetchToken() {
        const userData = localStorage.getItem('currentUser');
        let token = '';
        if (userData) {
            token = JSON.parse(userData).token;
        }
        return token;
    }

    getLoggedinUserName() {
        const userData = localStorage.getItem('currentUser');
        let name = '';
        if (userData) {
            name = JSON.parse(userData).name;
        }
        return name;
    }

    getLoggedinUserStatus() {
        const userData = localStorage.getItem('currentUser');
        let status = '';
        if (userData) {
            status = JSON.parse(userData).status;
        }
        return status;
    }

    updateLoggedinUserName(name) {
        const userData = localStorage.getItem('currentUser');
        let data = {name : '', id : '', user_type : '', token : '' };
        if (userData) {
            data = JSON.parse(userData);
             data.name = name;
             localStorage.setItem('currentUser', JSON.stringify(data));
        }
    }

    updateLoggedinUserStatus(status) {
        const userData = localStorage.getItem('currentUser');
        let data = {name : '', id : '', user_type : '', token : '', status: '' };
        if (userData) {
            data = JSON.parse(userData);
             data.status = status;
             localStorage.setItem('currentUser', JSON.stringify(data));
        }
    }

    deleteToken() {
        localStorage.removeItem('currentUser');
    }

    setSocketData(data) {
        localStorage.setItem('socketData', JSON.stringify(data));
    }

    getSocketData() {
        const socketData = localStorage.getItem('socketData');
        let data = {from : ''};
        if (socketData) {
            data = JSON.parse(socketData);
        }
        return data;
    }

    deleteSocketData() {
        localStorage.removeItem('socketData');
    }

}
